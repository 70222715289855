import { Done as DoneIcon } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CircularProgress,
  Fade,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/jobs.store";

import { AsolviSignoff } from "components/AsolviSignoff";
import { AsolviSwitch } from "components/AsolviSwitch";
import BackdropWhite from "components/BackdropWhite";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import engineerSettings from "helpers/engineerSettings";
import { JobVisitSummary } from "./JobVisitSummary";
import { Signoff } from "./Signoff";

interface SignoffDialogProps {
  handleSignature: (value: string | undefined) => void;
  disableNextButton: boolean;
  loading: boolean;
  openCustomerReview: () => void;
}

export const EngineerSignoffComponent: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { disableNextButton, loading, openCustomerReview, handleSignature } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { solutionDescription, followUp, errors, signatureEngineer } =
    useAppSelector(selectSelectedJobVisit);
  const requireEngineerSignature = engineerSettings.requireEngineerSignature ?? false;
  const storeSignaturesLocally = engineerSettings.storeSignaturesLocal ?? false;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <JobVisitSummary isEngineerSummaryPage={true} />
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            <FormattedMessage id="visit.followUp.required" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            key={`FollowUpSwitch`}
            checked={followUp.followUpChecked}
            onChange={() =>
              dispatch(
                setVisitValue({
                  key: "followUp",
                  value: { ...followUp, followUpChecked: !followUp.followUpChecked },
                })
              )
            }
            data-testid="EngineerSignoffDialog-FollowUpButton"
          />
        </Grid>
      </Grid>
      {followUp.followUpChecked && (
        <Fade in={followUp.followUpChecked}>
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item width="100%" marginTop="16px">
              <StyledTextField
                id="reason"
                name="reason"
                label={intl.formatMessage({ id: "visit.followUp.reason" })}
                value={solutionDescription}
                onChange={({ target: { value } }) => {
                  dispatch(setVisitValue({ key: "solutionDescription", value }));
                }}
                multiline
                rows={10}
              />
              {solutionDescription.length === 0 &&
                engineerSettings?.requiredSolutionDescription && (
                  <FormHelperText error>
                    {intl.formatMessage({
                      id: "visit.followUp.reasonRequired",
                    })}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
        </Fade>
      )}

      {requireEngineerSignature && (
        <Grid item sx={{ width: "100%" }}>
          {storeSignaturesLocally ? (
            <Signoff
              handleSignature={handleSignature}
              error={errors.engineerSignature}
              label={intl.formatMessage({ id: "visit.engineerSignature" })}
              signatureData={signatureEngineer}
            />
          ) : (
            <Card elevation={1}>
              <CardContent>
                <AsolviSignoff
                  handleSignature={handleSignature}
                  labelText={intl.formatMessage({ id: "visit.engineerSignature" })}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      )}
      <Grid item pb={1.5}>
        <BackdropWhite open={loading}>
          <CircularProgress color="inherit" />
        </BackdropWhite>

        <PrimaryButton
          key={`to-customer-summary-button`}
          startIcon={<DoneIcon />}
          variant="contained"
          onClick={openCustomerReview}
          fullWidth
          disabled={disableNextButton}
        >
          <FormattedMessage id="general.next" />
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
