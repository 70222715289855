import CreateIcon from "@mui/icons-material/Create";
import { FormHelperText, Grid, Typography } from "@mui/material";
import FullScreenDialog from "components/FullScreenDialog";
import { SignatureDialog } from "components/job/SignatureDialog";
import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "store";
import { selectSelectedJob } from "store/slices/jobs.store";

interface SignoffProps {
  handleSignature: (value: string) => void;
  signatureData: string | undefined;
  error?: string;
  label: string;
}

export const Signoff = (props: SignoffProps) => {
  const { handleSignature, error, label, signatureData } = props;
  const job = useAppSelector(selectSelectedJob);
  const intl = useIntl();
  const [errorText, setErrorText] = useState<string>("");
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [imageWidth, setImageWidth] = useState(window.innerWidth - 50);
  const [signature, setSignature] = useState<string>("");

  const errorStyle = {
    outlineColor: "red",
    outlineWidth: "1px",
    outlineStyle: "solid",
    margin: "2px",
  };

  useEffect(() => {
    if (error) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, error]);

  useEffect(() => {
    const resizeImage = () => {
      setImageWidth(window.innerWidth - 50);
    };

    resizeImage();

    window.addEventListener("resize", resizeImage);
    return () => window.removeEventListener("resize", resizeImage);
  }, []);

  const handleSignatureInput = useCallback(
    (value: string) => {
      handleSignature(value);

      if (!value) {
        setSignature("");
      } else {
        setSignature(JSON.stringify([{ name: `${job.id}_signature.png`, data: value }]));
      }
    },
    [handleSignature, job.id]
  );

  useEffect(() => {
    handleSignatureInput(signatureData ?? "");
  }, [handleSignatureInput, signatureData]);

  return (
    <Grid container direction="column" data-testid="SignatureType">
      <Grid item>
        <PrimaryButton
          onClick={() => {
            setSignatureDialogOpen(true);
          }}
          variant="contained"
          color="info"
          fullWidth
          sx={!!errorText ? errorStyle : undefined}
          data-testid="SignatureTypeDialogOpenButton"
          startIcon={<CreateIcon />}
        >
          {label}
        </PrimaryButton>
      </Grid>

      {signature !== "" ? (
        <>
          <Grid item container justifyContent="space-around">
            <img
              src={JSON.parse(signature)[0].data}
              alt="signature preview"
              style={{
                maxWidth: imageWidth,
                maxHeight: "150px",
                width: "auto",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item>
            <FormHelperText error>{errorText}</FormHelperText>
          </Grid>
        </>
      ) : (
        <Grid item>
          <Typography>
            <FormattedMessage id="checklist.noSignatureAdded" />
          </Typography>
        </Grid>
      )}
      <FullScreenDialog
        open={signatureDialogOpen}
        setOpen={setSignatureDialogOpen}
        title={`${job?.externalId} / ${label}`}
        centerTitle
        goingBack
        child={
          <SignatureDialog
            onSave={handleSignatureInput}
            handleClose={() => {
              setSignatureDialogOpen(false);
            }}
          />
        }
      />
    </Grid>
  );
};
