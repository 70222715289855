import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import {
  GetCustomerByMachineQueryVariables,
  GetCustomerQueryVariables,
  GetCustomersQueryVariables,
  GetEquipmentQueryVariables,
} from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./createJob.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getCreateJobOptions: createAppAsyncThunk(
    "createJob/getCreateJobOptions",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCreateJobOptions);
      if (errors) return rejectWithValue(errors);
      if (!data?.createJobOptions) return rejectWithValue("something went wrong");
      const { createJobOptions } = data;
      const { categories, symptoms } = createJobOptions || {};
      if (categories && symptoms) return { categories, symptoms };
      else return rejectWithValue("Something went wrong");
    }
  ),
  getCustomer: createAppAsyncThunk(
    "createJob/getCustomer",
    async (variables: GetCustomerQueryVariables, { rejectWithValue, getState, extra: { sdk } }) => {
      const { createJob } = getState();
      let existingCustomer = createJob.customers.find((c) => c.name === variables.nameFilter);
      if (existingCustomer) return existingCustomer;
      const { data, errors } = await graphqlRequest(sdk.getCustomer, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.customer) return rejectWithValue("something went wrong");
      return data.customer;
    }
  ),
  getCustomerByMachine: createAppAsyncThunk(
    "createJob/getCustomerByMachine",
    async (
      variables: GetCustomerByMachineQueryVariables,
      { rejectWithValue, getState, extra: { sdk } }
    ) => {
      const { data, errors } = await graphqlRequest(sdk.getCustomerByMachine, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.customerByMachine) return rejectWithValue("something went wrong");
      return data.customerByMachine;
    }
  ),
  getCustomers: createAppAsyncThunk(
    "createJob/getCustomers",
    async (variables: GetCustomersQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCustomers, { variables });
      if (errors) return rejectWithValue(errors);
      if (!data?.customers) return rejectWithValue("something went wrong");
      return data.customers;
    }
  ),
  getEquipment: createAppAsyncThunk(
    "createJob/getEquipment",
    async (variables: GetEquipmentQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEquipment, { variables });
      if (errors) return rejectWithValue(errors);
      if (!data?.equipment) return rejectWithValue("something went wrong");
      return data.equipment;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getCreateJobOptions.pending, (state) => {
    state.initLoading = true;
    return state;
  });
  builder.addCase(asyncQueries.getCreateJobOptions.rejected, (state) => {
    state.initLoading = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getCreateJobOptions.fulfilled,
    (state, { payload: { categories, symptoms } }) => {
      state.initLoading = false;
      state.categories = [...categories];
      state.symptoms = [...symptoms];

      return state;
    }
  );
  builder.addCase(asyncQueries.getCustomer.pending, (state) => {
    state.customersLoading = true;
  });
  builder.addCase(asyncQueries.getCustomer.rejected, (state) => {
    state.customersLoading = false;
  });
  builder.addCase(asyncQueries.getCustomer.fulfilled, (state, { payload: customer }) => {
    state.customersLoading = false;
    const existingCustomer = state.customers.find((c) => c.id === customer.id);
    if (!existingCustomer) state.customers = [...state.customers, customer];
    return state;
  });
  builder.addCase(asyncQueries.getCustomerByMachine.pending, (state) => {
    state.customersLoading = true;
  });
  builder.addCase(asyncQueries.getCustomerByMachine.rejected, (state) => {
    state.customersLoading = false;
  });
  builder.addCase(asyncQueries.getCustomerByMachine.fulfilled, (state, { payload: customer }) => {
    state.customersLoading = false;
    const existingCustomer = state.customers.find((c) => c.id === customer.id);
    if (!existingCustomer) state.customers = [...state.customers, customer];
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.pending, (state) => {
    state.customersLoading = true;
  });
  builder.addCase(asyncQueries.getCustomers.rejected, (state) => {
    state.customersLoading = false;
  });
  builder.addCase(asyncQueries.getCustomers.fulfilled, (state, { payload: customers }) => {
    state.customersLoading = false;
    state.customers = [...customers];
    return state;
  });
  builder.addCase(asyncQueries.getEquipment.pending, (state) => {
    state.equipmentsLoading = true;
  });
  builder.addCase(asyncQueries.getEquipment.rejected, (state, { payload: errors }) => {
    state.equipmentsLoading = false;
  });
  builder.addCase(asyncQueries.getEquipment.fulfilled, (state, { payload: equipment }) => {
    state.equipmentsLoading = false;
    state.equipments = [...equipment];
    return state;
  });
};
