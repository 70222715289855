import { Action, createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RootState } from "store";
import { asyncMutations, mutationBuilder } from "./note.mutations";

// Interface for state
export interface State {
  open: boolean;
  loading: boolean;
  text: string | undefined;
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  setOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setText: (state: State, action: PayloadAction<{ text: string | undefined }>) => State;
  handleClose: (state: State, action: Action) => State;
  addWorkNoteQueued: (state: State, action: Action) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  selectNoteDialog: (state: RootState) => State;
  selectNoteDialogOpen: (state: RootState) => boolean;
  selectAddWorkNoteLoading: (state: RootState) => boolean;
}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  open: false,
  loading: false,
  text: undefined,
};
/** Example state
 * feedback: false
 */

// Definition of actual actions
const actions: Actions = {
  setOpen: (state, { payload: { open } }) => {
    state.open = open;
    return state;
  },
  setText: (state, { payload: { text } }) => {
    state.text = text;
    return state;
  },
  handleClose: (state) => {
    state.loading = false;
    state.open = false;
    state.text = "";
    return state;
  },
  addWorkNoteQueued: (state) => {
    state.loading = false;
    state.open = false;
    state.text = "";
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  selectNoteDialog: ({ note }) => {
    return note;
  },
  selectNoteDialogOpen: ({ note }) => {
    return note.open;
  },
  selectAddWorkNoteLoading: ({ note }) => {
    return note.loading;
  },
};
/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

// * note: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "note",
  initialState,
  reducers: actions,
  extraReducers: mutationBuilder,
});

// To be imported and added in store/reducers
export default storeBase.reducer;

// Export all actions created in store, to be used in components
// I.e, if using setOpen defined in examples above, it would look like this
// export const { setOpen } = storeBase.actions;
export const { setOpen, setText, handleClose } = storeBase.actions;
export const { addWorkNote } = asyncMutations;

// Export all selectors created in store, to be used in components
// I.e, if using selectFeedback defined in examples above, it would look like this
// export const { selectFeedback } = selectors;
export const { selectNoteDialog, selectNoteDialogOpen, selectAddWorkNoteLoading } = selectors;
