import { FC } from "react";

import { JobDetails } from "pages/jobs/jobDetails/JobDetails";

export const JobDetailsPage: FC = () => {
  return (
    <>
      <JobDetails />
    </>
  );
};
