import {
  ArrowBack as ArrowBackIcon,
  ContentCopy as ContentCopyIcon,
  CropFree as CropFreeIcon,
  ErrorRounded as ErrorRoundedIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  CircularProgress,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import { BarcodeScannerDialog } from "components/barcodeScan";
import { EditPhoto } from "components/editPhoto/EditPhoto";
import { FilePreview } from "components/FilePreview";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import { SymptomAutocomplete } from "components/SymptomAutocomplete";
import { scanditLicense } from "env";
import { addressToPostalLocation, addressToString, isAbortError, resizeUploadFile } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import { useDebounce } from "hooks/useDebounce";
import { nanoid } from "nanoid";
import {
  CustomerType,
  EngineerType,
  FileType,
  JobCategoryType,
  JobStatus,
  Maybe,
} from "operations/schema/schema";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store";
import { getEngineers } from "store/slices/cache.store";
import {
  createJob,
  getCreateJobOptions,
  getCustomer,
  getCustomerByMachine,
  getCustomers,
  getEquipment,
  handleClose,
  selectCreateJobForm,
  selectCreateJobOpen,
  selectCreateJobOptions,
  setValue,
  validateForm,
} from "store/slices/dialogs/createJob.store";
import { downloadJobs, getJobs, selectIncompleteJobs } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const StyledDetails = styled("div")(() => ({
  marginTop: 5,
  marginLeft: 14,
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: "bold",
  margin: `${theme.spacing(1)} 0`,
  cursor: "pointer",
}));

interface CreateJobDialogProps {
  customer?: Maybe<CustomerType>;
}

export const CreateJobDialog: FC<CreateJobDialogProps> = (props) => {
  const { customer: propsCustomer } = props;
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectCreateJobOpen);
  const {
    initLoading,
    createJobLoading,
    isValid,
    isDirty,
    categories,
    customers,
    customersLoading,
    equipments,
    equipmentsLoading,
    errors,
    symptoms,
  } = useAppSelector(selectCreateJobOptions);
  const {
    category,
    customer,
    engineer,
    description,
    equipment,
    files,
    symptomCode1,
    symptomCode2,
    symptomCode3,
  } = useAppSelector(selectCreateJobForm);
  const { loading, engineers } = useAppSelector((s) => s.cache);
  const { userVar } = useAppSelector((s) => s.user);
  const intl = useIntl();
  const hasBarcodeScanner = !!scanditLicense();
  const [currentPhoto, setCurrentPhoto] = useState<FileType | undefined>();
  const [equipmentACOpen, setEquipmentACOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [scannerValue, setScannerValue] = useState<string>("");
  const [customerSearch, setCustomerSearch] = useState<string>("");
  const [equipmentSearch, setEquipmentSearch] = useState<string>("");
  const debouncedEquipmentSearch = useDebounce<string>(equipmentSearch, 500);
  const debouncedCustomerSearch = useDebounce<string>(customerSearch, 500);
  const jobs = useAppSelector(selectIncompleteJobs, shallowEqual);

  const close = () => {
    // setCustomerSearch("");
    dispatch(handleClose());
  };

  const handleFileInput = async (e: any) => {
    const file = e.target.files[0];

    if (file) {
      resizeUploadFile(file).then((data) => {
        const output = typeof data === "string" ? data : "";
        const newFile: FileType = {
          id: nanoid(),
          name: file.name,
          data: output,
          description: "",
          size: output.length,
        };
        dispatch(setValue({ field: "files", value: [newFile, ...files] }));
      });
    }

    e.target.value = null;
  };

  const setCustomer = useCallback(
    async (
      id: string | null | undefined,
      name: string | null | undefined,
      machineGuid: string | null | undefined
    ) => {
      if (id) {
        let customer = customers.find((x) => x.id === id);
        if (!customer) {
          if (machineGuid) {
            await dispatch(getCustomerByMachine({ machineGuid: machineGuid }));
          } else if (name) {
            await dispatch(getCustomer({ nameFilter: name }));
          }
          customer = customers.find((x) => x.id === id);
        }
        if (customer) {
          dispatch(setValue({ field: "customer", value: customer }));
        } else {
          console.log("Something went wrong");
        }
      }
    },
    [customers, dispatch]
  );

  useEffect(() => {
    if (open) {
      dispatch(getCreateJobOptions());
      dispatch(getEngineers());
    }
  }, [dispatch, open, propsCustomer?.id, propsCustomer?.name]);

  useEffect(() => {
    if (open) {
      if (propsCustomer?.id) {
        setCustomer(propsCustomer?.id, propsCustomer?.name, null);
      }
    }
  }, [open, propsCustomer, setCustomer]);

  useEffect(() => {
    if (open) {
      dispatch(
        getEquipment({
          serialNumber: debouncedEquipmentSearch,
          customerId: customer?.id,
        })
      );
    }
  }, [debouncedEquipmentSearch, customer, open, dispatch]);

  useEffect(() => {
    if (open) {
      dispatch(getCustomers({ nameFilter: debouncedCustomerSearch }));
    }
  }, [debouncedCustomerSearch, dispatch, open]);

  useEffect(() => {
    if (open) {
      if (equipment?.id) {
        if (equipment.customerId) {
          setCustomer(equipment.customerId, equipment.customerName, equipment.id);
        }
      }
    }
  }, [equipment, open, setCustomer, dispatch]);

  const copyText = () => {
    const symptomText1 = symptoms?.find((a) => a?.code === symptomCode1)?.description;
    const symptomText2 = symptoms?.find((a) => a?.code === symptomCode2)?.description;
    const symptomText3 = symptoms?.find((a) => a?.code === symptomCode3)?.description;

    const symptomString = [symptomText1, symptomText2, symptomText3]
      .filter((a) => a ?? a)
      .join(", ");

    dispatch(
      setValue({
        field: "description",
        value: symptomString,
      })
    );
  };

  const onSubmit = async () => {
    dispatch(validateForm());
    if (!isValid) return;
    dispatch(createJob())
      .then(unwrapResult)
      .then(({ jobId, queued }) => {
        close();
        if (queued) {
          dispatch(addSnackbarMessage({ key: "CreateJob-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "CreateJob-success", argument: jobId! }));
          dispatch(getJobs({ force: true }))
            .then(unwrapResult)
            .then(() => {
              if (jobs.length > 0) {
                var jobIds: string[] = [];
                jobs.forEach((job) => {
                  if (job.status === JobStatus.Assigned) {
                    jobIds.push(job.id);
                  }
                });
                if (jobIds.length > 0) {
                  dispatch(
                    downloadJobs({
                      jobIds,
                    })
                  );
                }
              }
            });
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "CreateJob-fail" }));
      });
  };

  const mappedEngineers: EngineerType[] = useMemo(() => {
    const result: EngineerType[] = [];
    for (const engineer of engineers) {
      if (engineer.id === userVar?.externalId) {
        let myself: EngineerType = {
          ...engineer,
          name: intl.formatMessage({ id: "general.myself" }),
        };
        result.unshift(myself);
      } else if (engineerSettings?.isSupervisor) {
        result.push(engineer);
      }
    }
    return result;
  }, [engineers, intl, userVar?.externalId]);

  return (
    <Dialog fullScreen open={open} onClose={close} data-testid="CreateJobDialog">
      <BackdropPrimaryMain open={createJobLoading || initLoading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="CreateJobDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            <FormattedMessage id="menu.createNewJob" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        {!initLoading && (
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item container alignItems="start" justifyContent="space-between" wrap="nowrap">
                <Grid item width="100%">
                  <Autocomplete
                    open={equipmentACOpen}
                    onOpen={() => setEquipmentACOpen(true)}
                    onClose={() => setEquipmentACOpen(false)}
                    value={equipment}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        dispatch(setValue({ field: "equipment", value: null }));
                        setEquipmentSearch("");
                      } else {
                        if (value) {
                          dispatch(setValue({ field: "equipment", value }));
                        }
                      }
                    }}
                    getOptionLabel={(o) => `${o.serialNumber}`}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          key={`${option.id}-${option.name}`}
                          style={{ display: "block" }}
                        >
                          <Typography variant="body1">{option.serialNumber}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {option.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {option.assetNumber}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {option.location}
                          </Typography>
                        </li>
                      );
                    }}
                    inputValue={equipmentSearch}
                    onInputChange={(_, value) => {
                      if (scannerValue && !value) {
                        setEquipmentSearch(scannerValue);
                      } else {
                        setScannerValue("");
                        setEquipmentSearch(value);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value && value.id ? option.id === value.id : true
                    }
                    filterOptions={(x) => x}
                    options={equipments}
                    loading={equipmentsLoading}
                    renderInput={(params) => (
                      <StyledTextField
                        name="equipment"
                        error={!!errors?.equipment}
                        {...params}
                        label={intl.formatMessage({
                          id: "job.equipment.serialNumber",
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {equipmentsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {!!equipment?.id && (
                    <StyledDetails>
                      {!!equipment.location && (
                        <Typography variant="body2" color="textSecondary">
                          {equipment.location}
                        </Typography>
                      )}
                      {!!equipment.name && (
                        <Typography variant="body2" color="textSecondary">
                          {equipment.name}
                        </Typography>
                      )}
                      {!!equipment.assetNumber && (
                        <Typography variant="body2" color="textSecondary">
                          {equipment.assetNumber}
                        </Typography>
                      )}
                      {!!equipment.address && (
                        <Typography variant="body2" color="textSecondary">
                          {addressToString(equipment.address)}
                        </Typography>
                      )}
                    </StyledDetails>
                  )}
                  <FormHelperText error>{errors?.equipment}</FormHelperText>
                </Grid>
                {hasBarcodeScanner && (
                  <Grid item>
                    <IconButton
                      onClick={() => setOpenScanner(true)}
                      size="large"
                      sx={{ pr: 0, ml: 1 }}
                    >
                      <CropFreeIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Autocomplete
                  disableClearable={!!equipment?.id}
                  value={customer}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      dispatch(setValue({ field: "customer", value: null }));
                    } else {
                      if (value) {
                        setEquipmentSearch("");
                        setCustomerSearch("");
                        dispatch(setValue({ field: "customer", value }));
                      }
                    }
                  }}
                  getOptionLabel={(o) => `${o.name}`}
                  renderOption={(props, option) => (
                    <li {...props} key={`${option.id}-${option.name}`} style={{ display: "block" }}>
                      <Typography variant="body1">{option.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {addressToPostalLocation(option.address)}
                      </Typography>
                    </li>
                  )}
                  inputValue={customerSearch}
                  onInputChange={(_, value) => {
                    setCustomerSearch(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value && value.id ? option.id === value.id : true
                  }
                  filterOptions={(x) => x}
                  options={customers}
                  loading={customersLoading}
                  renderInput={(params) => (
                    <StyledTextField
                      name="customer"
                      error={!!errors?.customer}
                      {...params}
                      label={intl.formatMessage({
                        id: "general.customer",
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {customersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                      required
                    />
                  )}
                />
                {!!customer?.id && customer.isOnStop && (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 5,
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <ErrorRoundedIcon color="error" sx={{ mr: 0.5 }} />
                    <FormattedMessage id="general.onStop" />
                  </div>
                )}
                <FormHelperText error>{errors?.customer}</FormHelperText>
              </Grid>
              {!!categories?.length && (
                <Grid item>
                  <Autocomplete
                    options={categories}
                    getOptionLabel={(c: JobCategoryType) => `${c.text}`}
                    renderInput={(params) => (
                      <StyledTextField
                        name="categoryId"
                        error={!!errors?.category}
                        {...params}
                        label={intl.formatMessage({ id: "job.category" })}
                        required
                      />
                    )}
                    onChange={(_, value: JobCategoryType | null) => {
                      dispatch(
                        setValue({
                          field: "category",
                          value: value,
                        })
                      );
                      if (!value) dispatch(setValue({ field: "symptomCode1", value: null }));
                    }}
                    fullWidth
                  />
                  <FormHelperText error>{errors?.category}</FormHelperText>
                </Grid>
              )}
              {!!symptoms?.length && (
                <>
                  <SymptomAutocomplete
                    symptoms={symptoms}
                    category={category}
                    errors={errors}
                    onChange={(field, value, shouldValidate) => {
                      dispatch(setValue({ field, value, shouldValidate }));
                    }}
                  />
                  <StyledGrid
                    item
                    container
                    alignItems="center"
                    spacing={1}
                    onClick={copyText}
                    data-testid="CreateJobDialog-CopyAction"
                  >
                    <Grid item>
                      <ContentCopyIcon />
                    </Grid>
                    <Grid item>
                      {intl.formatMessage({
                        id: "createJob.copySymptomText",
                      })}
                    </Grid>
                  </StyledGrid>
                </>
              )}
              <Grid item>
                <StyledTextField
                  name="description"
                  fullWidth
                  required={engineerSettings.requireSymtomText}
                  label={intl.formatMessage({ id: "createJob.description" })}
                  multiline
                  rows={5}
                  value={description}
                  onChange={(e: any) => {
                    dispatch(
                      setValue({
                        field: "description",
                        value: e.target.value,
                      })
                    );
                  }}
                  error={!!errors?.description}
                />
                <FormHelperText error>{errors?.description}</FormHelperText>
              </Grid>
              <Grid item>
                <Autocomplete
                  loading={loading.engineers}
                  value={engineer}
                  options={mappedEngineers}
                  getOptionLabel={(e: EngineerType) => `${e.name}`}
                  renderInput={(params) => (
                    <StyledTextField
                      name="engineer"
                      error={!!errors?.category}
                      {...params}
                      label={intl.formatMessage({ id: "general.engineer" })}
                    />
                  )}
                  onChange={(_, value: EngineerType | null) => {
                    dispatch(setValue({ field: "engineer", value: value }));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <input
                  accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                  id="create-job-attach-file"
                  type="file"
                  data-testid="CreateJobDialog-FileInput"
                  onChange={handleFileInput}
                  hidden
                />
                <label htmlFor="create-job-attach-file">
                  <SecondaryButton
                    startIcon={<UploadFileIcon />}
                    sx={{ justifyContent: "flex-start" }}
                    component="span"
                  >
                    {intl.formatMessage({ id: "createJob.attachFile" })}
                  </SecondaryButton>
                </label>
              </Grid>
              {files?.map((file, index) => (
                <FilePreview
                  key={file.id}
                  file={file}
                  onDelete={() => {
                    dispatch(
                      setValue({
                        field: "files",
                        value: files.filter((_, idx) => idx !== index),
                      })
                    );
                  }}
                  onEdit={() => {
                    setCurrentPhoto(file);
                    setOpenEditDialog(true);
                  }}
                />
              ))}
              <Grid item>
                <PrimaryButton
                  key={`create-job-submit-button`}
                  fullWidth
                  variant="contained"
                  disabled={!isValid || !isDirty}
                  type="submit"
                  data-testid="CreateJobDialog-SaveButton"
                  onClick={onSubmit}
                >
                  <FormattedMessage id="general.save" />
                </PrimaryButton>
              </Grid>
            </Grid>
            {hasBarcodeScanner && (
              <BarcodeScannerDialog
                open={openScanner}
                setOpen={(open: boolean) => setOpenScanner(open)}
                submitValue={(value: string) => {
                  setScannerValue(value);
                  setEquipmentSearch(value);
                  setOpenScanner(false);
                  setEquipmentACOpen(true);
                }}
              />
            )}
          </>
        )}
      </StyledContainer>
      <FullScreenDialog
        open={openEditDialog}
        setOpen={(open: boolean) => setOpenCloseConfirmation(!open)}
        child={
          <EditPhoto
            setOpenEditDialog={setOpenEditDialog}
            currentPhoto={currentPhoto}
            onSave={(result) => {
              const indexToUpdate = files.findIndex((x) => x.id === currentPhoto?.id);
              if (indexToUpdate > -1) {
                const filesTemp = [...files];
                filesTemp[indexToUpdate] = result;
                dispatch(setValue({ field: "files", value: filesTemp }));
              }
            }}
            openCloseConfirmation={openCloseConfirmation}
            setOpenCloseConfirmation={setOpenCloseConfirmation}
          />
        }
      />
    </Dialog>
  );
};
