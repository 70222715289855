import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { Job } from "models/Job";
import { ServiceJob, WorkNoteType, WorkNoteTypeEnum } from "operations/schema/schema";
import { RootState } from "store";

export type PageTypes = Extract<"jobs" | "planner" | "history", keyof RootState>;

// Interface for state
export interface State {
  page: PageTypes;
  selectedJobId: string | undefined;
  connectionStatus: boolean;
  forcedOffline: boolean;
  queueLength: number;
}

interface Actions extends SliceCaseReducers<State> {
  setPage: (state: State, action: PayloadAction<{ page: PageTypes }>) => State;
  setSelectedJob: (state: State, action: PayloadAction<{ jobId: string | undefined }>) => State;
  setConnectionStatus: (state: State, action: PayloadAction<{ status: boolean }>) => State;
  setForcedOffline: (state: State, action: PayloadAction<{ status: boolean }>) => State;
  addQueueLength: (state: State) => State;
  subtractQueueLength: (state: State) => State;
}

interface Selectors {
  selectPageSelectedJob: (state: RootState) => Job;
  selectSelectedJobId: (state: RootState) => string | undefined;
  selectLoadingJob: (state: RootState) => boolean;
  selectLoadingRelatedJobs: (state: RootState) => boolean;
  selectConnectionStatus: (state: RootState) => boolean;
  selectForcedOffline: (state: RootState) => boolean;
  selectPageVisits: (state: RootState) => ServiceJob[];
  selectPageWorkNotes: (state: RootState) => WorkNoteType[];
  selectQueueLength: (state: RootState) => number;
}

export const initialState: State = {
  page: "jobs",
  selectedJobId: undefined,
  connectionStatus: true,
  forcedOffline: false,
  queueLength: 0,
};

const actions: Actions = {
  setPage(state, { payload: { page } }) {
    state.page = page;
    return state;
  },
  setSelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setConnectionStatus: (state, { payload: { status } }) => {
    state.connectionStatus = status;
    return state;
  },
  setForcedOffline: (state, { payload: { status } }) => {
    state.forcedOffline = status;
    return state;
  },
  addQueueLength(state) {
    state.queueLength += 1;
    return state;
  },
  subtractQueueLength(state) {
    state.queueLength -= 1;
    if (state.queueLength < 0) state.queueLength = 0;
    return state;
  },
};

const selectors: Selectors = {
  selectPageSelectedJob(state) {
    let {
      root: { selectedJobId, page },
    } = state;
    if (!selectedJobId || !state[page].jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return state[page].jobs[selectedJobId];
  },
  selectSelectedJobId({ root: { selectedJobId } }) {
    return selectedJobId;
  },
  selectLoadingJob(state) {
    let {
      root: { page },
    } = state;
    return !!state[page].loadingJob;
  },
  selectLoadingRelatedJobs(state) {
    let {
      root: { page },
    } = state;
    return !!state[page].loadingRelatedJobs;
  },
  selectConnectionStatus({ root: { connectionStatus, forcedOffline } }) {
    return connectionStatus && !forcedOffline;
  },
  selectForcedOffline({ root: { forcedOffline } }) {
    return forcedOffline;
  },
  selectPageVisits(state) {
    let {
      root: { selectedJobId, page },
    } = state;
    if (!selectedJobId || !state[page].jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return state[page].jobs[selectedJobId].visits;
  },
  selectPageWorkNotes(state) {
    let {
      root: { selectedJobId, page },
    } = state;
    if (!selectedJobId || !state[page].jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return state[page].jobs[selectedJobId].workNotes.filter(
      (wn) => wn.type === WorkNoteTypeEnum.Ticket
    );
  },
  selectQueueLength(state) {
    return state.root.queueLength;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "root",
  initialState,
  reducers: actions,
});

// To be imported and added in store/reducers
export default storeBase.reducer;

export const {
  setPage,
  setSelectedJob,
  setConnectionStatus,
  setForcedOffline,
  addQueueLength,
  subtractQueueLength,
} = storeBase.actions;

export const {
  selectPageSelectedJob,
  selectSelectedJobId,
  selectLoadingJob,
  selectLoadingRelatedJobs,
  selectConnectionStatus,
  selectForcedOffline,
  selectPageVisits,
  selectPageWorkNotes,
  selectQueueLength,
} = selectors;
