import { FC } from "react";
import { PlannerActions } from "./PlannerActions";
import { PlannerJobDetails } from "./PlannerJobDetails";

export const PlannerDetailsPage: FC = () => {
  return (
    <>
      <PlannerJobDetails />
      <PlannerActions />
    </>
  );
};
