import { Autocomplete } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import { InvoiceableTypes, isEmpty } from "helpers";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";
import { selectSelectedJob } from "store/slices/jobs.store";

export interface InvoiceAutocompleteProps {
  onChange: (value: any) => void;
  value: any;
}

interface AutocompleteOption {
  value: string | null;
  label: string;
}

export const InvoiceAutocomplete: FC<InvoiceAutocompleteProps> = (props) => {
  const { onChange, value } = props;
  const intl = useIntl();
  const { contractId } = useAppSelector(selectSelectedJob);

  const invoiceOptions: AutocompleteOption[] = useMemo(() => {
    const options: AutocompleteOption[] = [
      {
        value: InvoiceableTypes.INVOICEABLE,
        label: intl.formatMessage({ id: "visit.invoiceable" }),
      },
      {
        value: InvoiceableTypes.NOT_INVOICEABLE,
        label: intl.formatMessage({ id: "visit.notInvoiceable" }),
      },
    ];
    if (!isEmpty(contractId)) {
      options.unshift({
        value: InvoiceableTypes.CONTRACT,
        label: intl.formatMessage({ id: "visit.contract" }),
      });
    }
    return options;
  }, [contractId, intl]);

  const findMatch = (value: string) => {
    const match = invoiceOptions.find((o) => o.value === value);
    return match ?? null;
  };

  return (
    <Autocomplete
      fullWidth
      options={invoiceOptions}
      getOptionLabel={(o) => `${o.label}`}
      value={findMatch(value)}
      renderInput={(params) => (
        <StyledTextField
          name="invoiceType"
          {...params}
          label={intl.formatMessage({ id: "visit.invoice" })}
        />
      )}
      onChange={(_, value) => {
        onChange(value?.value);
      }}
    />
  );
};
