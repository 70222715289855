import { Maybe } from "operations/schema/schema";
import { isEmpty } from "./isEmpty";

export const InvoiceableTypes = {
  CONTRACT: null,
  INVOICEABLE: "1",
  NOT_INVOICEABLE: "0",
};

export const getInvoiceableDefaultValue = (contractId?: Maybe<string>) => {
  if (!isEmpty(contractId)) return InvoiceableTypes.CONTRACT;
  return InvoiceableTypes.INVOICEABLE;
};
