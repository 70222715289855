import { isMobileSafari } from "helpers";
import useShouldShowPrompt from "./useShouldShowPrompt";

const iosInstallPromptedAt = "iosInstallPromptedAt";

const useIosInstallPrompt = (): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt);

  const standalone = window.matchMedia("(display-mode: standalone)").matches;

  return [
    isMobileSafari() && userShouldBePromptedToInstall && !standalone,
    handleUserSeeingInstallPrompt,
  ];
};

export default useIosInstallPrompt;
